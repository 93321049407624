import React, { Component } from "react";
import { IconContext } from "react-icons";
import {
  AiOutlineLinkedin,
  AiFillGithub,
  AiFillTwitterSquare
} from "react-icons/ai";
import "./footer.css";

export default class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="footerContent">
          {/* <h3>abdulai sow</h3> */}
          <ul className="socials">
            <li>
              <a href="https://www.linkedin.com/in/abdulai-sow-50924325/">
                <IconContext.Provider value={{ className: "footerIcons" }}>
                  <AiOutlineLinkedin />
                </IconContext.Provider>
              </a>
            </li>
          </ul>
          <ul className="socials">
            <li>
              <a href="https://github.com/abdulai1sow">
                <IconContext.Provider value={{ className: "footerIcons" }}>
                  <AiFillGithub />
                </IconContext.Provider>
              </a>
            </li>
          </ul>
          <ul className="socials">
            <li>
              <a href="https://twitter.com/TechCrunch?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor">
                <IconContext.Provider value={{ className: "footerIcons" }}>
                  <AiFillTwitterSquare />
                </IconContext.Provider>
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-Bottom">
          <p>Designed & Built by abdulai sow &copy;2020</p>
        </div>
      </footer>
    );
  }
}
