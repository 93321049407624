// import photo from '../images/ *'

const Projects = [
  {
    id: 1,
    title: "Blog App",
    category: "Full Stack",
    img: "./images/blogApp.png",
    desc: "Full Stack app using MVC design with full CRUD. The Blog App can create an account and post what interests you. ",
    url: "https://asblogapp2022.herokuapp.com/",
  },
  {
    id: 2,
    title: "Rock Paper Scissors",
    category: "javascript",
    img: "./images/rps.png",
    desc: "In highschool we played this game to see who was going to get the ball first in a basketball game, this would be the case at home with siblings for the last cookie. its a reminder of the fun we had with a simple game.",
    url: "https://upbeat-neumann-a97b59.netlify.app/",
  },
  {
    id: 3,
    title: "Guess A Number",
    category: "javascript",
    img: "./images/guessNum.png",
    desc: "Guess A Number is made with Javascript, CSS, Html. You input a number  ",
    url: "https://neon-cranachan-8a59f9.netlify.app",
  },
  {
    id: 4,
    title: "Jeopardy",
    category: "React",
    img: "./images/jeopardy.png",
    desc: "Designed the app and implemented a user-friendly appearance.Utilized third-party API to get the Jeopardy information. the scoreboard will hold the points of the player.",
    url: "https://asow-jeopardy23.netlify.app",
  },
];

export default Projects;
