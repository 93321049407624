import React from "react";
import {
  AiFillLinkedin,
  AiOutlineGithub,
  AiFillTwitterSquare
} from "react-icons/ai";

export const links = [
  {
    id: 1,
    url: "#home",
    text: "home"
  },
  {
    id: 2,
    url: "#about",
    text: "about"
  },
  {
    id: 3,
    url: "#work",
    text: "work"
  },
  {
    id: 4,
    url: "#contact",
    text: "contact"
  }
];

export const social = [
         {
           id: 1,
           url: "https://www.linkedin.com/in/abdulai-sow-50924325/",
           icon: <AiFillLinkedin />
         },
         {
           id: 2,
           url: "https://github.com/abdulai1sow",
           icon: <AiOutlineGithub />
         },
         {
           id: 3,
           url:
             "https://twitter.com/TechCrunch?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
           icon: <AiFillTwitterSquare />
         }
       ];
